@font-face {
    font-family: Iskry ;
    src: url('./assets/font/Iskry_Regular.ttf');
}
body{
    overscroll-behavior-x: none;
    overflow: hidden;
}
*{
    margin: 0;
    padding: 0;
}
#container{
    width: 100vw;
    height: 100vh;
}
canvas{
    display: block;
}
#title{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: Iskry;
    color: white;
    font-size: 5em;
}
#subTitle{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: Iskry;
    color: white;
    font-size: 1em;
}

@media screen and (max-width:768px){
    #title{
        text-align: center;
        width: 100%;
        font-size: 2em;
    }
    #subTitle{
        text-align: center;
        width: 100%;
        font-size: 1em;
        top: 56%;

    }
}